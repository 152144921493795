import { useTheme } from '/machinery/Theme'

import totalSpriteWhite from '/images/sprites/total-sprite-white.png'
import stripeSpriteWhite from '/images/sprites/stripe-sprite-white.png'
import circleSpriteWhite from '/images/sprites/circle-sprite-white.png'
import circleSpriteBlack from '/images/sprites/circle-sprite-black.png'

import { themes } from '/themes'
import styles from './Highlight.css'

export function HighlightTotal({ text }) {
  return (
    <HighlightBase>
      {text}
      <div
        className={cx(styles.sprite, styles.totalSprite)}
        style={{ backgroundImage: `url(${totalSpriteWhite})` }}
      />
    </HighlightBase>
  )
}

export function HighlightCircle({ text }) {
  const theme = useTheme()
  const isInverted = theme === themes.lightGray || theme === themes.pink

  return (
    <HighlightBase>
      {text}
      <div
        className={cx(styles.sprite, styles.circleSprite)}
        style={{ backgroundImage: isInverted ? `url(${circleSpriteBlack})` : `url(${circleSpriteWhite})` }}
      />
    </HighlightBase>
  )
}

export function HighlightStripe({ text }) {
  return (
    <HighlightBase className={styles.componentStripe}>
      {text}
      <div
        className={cx(styles.sprite, styles.stripeSprite)}
        style={{ backgroundImage: `url(${stripeSpriteWhite})` }}
      />
    </HighlightBase>
  )
}

function HighlightBase({ children, className = undefined }) {
  return (
    <span className={cx(styles.componentBase, className)}>{children}</span>
  )
}
