import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { TextSecondaryMd } from '/features/buildingBlocks/Text'
import { LinkButtonPrimary } from '/features/buildingBlocks/Link'
import { ImageCover } from '/features/buildingBlocks/Image'
import { HeadingFormattedLg } from '/features/buildingBlocks/HeadingFormatted'
import { HighlightCircle } from '/features/buildingBlocks/Highlight'
import { useTranslate } from '/machinery/I18n'

import styles from './LinkedinCta.css'

export function LinkedinCta({ image, titleBlocks }) {
  const { __ } = useTranslate()
  const { ref: componentRef, wasInViewport } = useWasInViewport({ threshold: [0.2] })

  return (
    <div ref={componentRef} className={styles.component}>
      <div className={styles.imageLayout}>
        <ImageCover
          layoutClassName={cx(styles.image, wasInViewport && styles.wasInViewport)}
          aspectRatio={5 / 3}
          imgProps={{
            alt: image.alt,
            loading: 'lazy'
          }}
          {...{ image }}
        />
      </div>

      <div className={cx(styles.content, wasInViewport && styles.wasInViewport)}>
        <HeadingFormattedLg
          h={2}
          value={titleBlocks}
          highlightComponent={HighlightCircle}
        />
        <TextSecondaryMd>
          <p>{__`weet-wat-er-speelt-binnen-het-notariaat`}</p>
        </TextSecondaryMd>
        <LinkButtonPrimary href='https://www.linkedin.com/groups/9157022/' dataX='link-to-linkedin' target='_blank'>
          {__`bekijk-onze-linkedin`}
        </LinkButtonPrimary>
      </div>
    </div>
  )
}
