import styles from './Text.css'

export function TextPrimaryLg({ children, layoutClassName = undefined }) {
  return (
    <TextPrimaryBase className={cx(styles.componentPrimaryLg, layoutClassName)}>
      {children}
    </TextPrimaryBase>
  )
}

export function TextPrimaryMd({ children, layoutClassName = undefined }) {
  return (
    <TextPrimaryBase className={cx(styles.componentPrimaryMd, layoutClassName)}>
      {children}
    </TextPrimaryBase>
  )
}

function TextPrimaryBase({ children, className }) {
  return (
    <div className={cx(styles.componentPrimaryBase, className)}>{children}</div>
  )
}

export function TextSecondaryLg({ children, layoutClassName = undefined }) {
  return (
    <TextSecondaryBase
      className={cx(styles.componentSecondaryLg, layoutClassName)}
    >
      {children}
    </TextSecondaryBase>
  )
}

export function TextSecondaryMd({ children, layoutClassName = undefined }) {
  return (
    <TextSecondaryBase
      className={(styles.componentSecondaryMd, layoutClassName)}
    >
      {children}
    </TextSecondaryBase>
  )
}

export function TextSecondarySm({ children, layoutClassName = undefined }) {
  return (
    <TextSecondaryBase
      className={cx(styles.componentSecondarySm, layoutClassName)}
    >
      {children}
    </TextSecondaryBase>
  )
}

function TextSecondaryBase({ children, className }) {
  return (
    <div className={cx(styles.componentSecondaryBase, className)}>
      {children}
    </div>
  )
}
