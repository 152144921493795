import { PortableText } from '@portabletext/react'

import styles from './HeadingFormatted.css'

export function HeadingFormattedXl({ value, h = undefined, layoutClassName = undefined, highlightComponent: Highlight }) {
  /** @type {import('@portabletext/react').PortableTextProps['components']} */
  const components = React.useMemo(
    () => ({
      block: {
        normal: ({ children }) => <>{children}</>
      },
      marks: {
        strong: props => <span className={styles.alternateFont}>{props.children}</span>,
        highlight: props => <Highlight text={props.children} />,
      }
    }),
    [Highlight]
  )

  return (
    <HeadingFormattedBase className={styles.componentXl} {...{ h, layoutClassName }}>
      <PortableText {...{ value, components }} />
    </HeadingFormattedBase>
  )
}

export function HeadingFormattedLg({ value, h = undefined, layoutClassName = undefined, highlightComponent: Highlight, noIndent = undefined }) {
  /** @type {import('@portabletext/react').PortableTextProps['components']} */
  const components = React.useMemo(
    () => ({
      block: {
        normal: ({ children }) => <>{children}</>
      },
      marks: {
        strong: props => <span className={styles.alternateFont}>{props.children}</span>,
        highlight: props => <Highlight text={props.children} />,
      }
    }),
    [Highlight]
  )

  return (
    <HeadingFormattedBase className={cx(styles.componentLg, noIndent && styles.noIndent)} {...{ h, layoutClassName }}>
      <PortableText {...{ components, value }} />
    </HeadingFormattedBase>
  )
}

export function HeadingFormattedMd({ value, h = undefined, layoutClassName = undefined, highlightComponent: Highlight }) {
  /** @type {import('@portabletext/react').PortableTextProps['components']} */
  const components = React.useMemo(
    () => ({
      block: {
        normal: ({ children }) => <>{children}</>
      },
      marks: {
        strong: props => <span className={styles.alternateFont}>{props.children}</span>,
        highlight: props => <Highlight text={props.children} />,
      }
    }),
    [Highlight]
  )

  return (
    <HeadingFormattedBase className={styles.componentMd} {...{ h, layoutClassName }}>
      <PortableText {...{ components, value }} />
    </HeadingFormattedBase>
  )
}

function HeadingFormattedBase({ h, children, className, layoutClassName }) {
  /**@type {any} */
  const H = h === undefined ? 'strong' : `h${h}`

  return <H className={cx(styles.componentBase, className, layoutClassName)} {...{ children }} />
}
