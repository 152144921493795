import green from './green.css'
import pink from './pink.css'
import gray from './gray.css'
import blue from './blue.css'
import lightGray from './lightGray.css'

export const themes = {
  green,
  pink,
  gray,
  blue,
  lightGray
}
