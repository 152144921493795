import { useTheme } from '/machinery/Theme'

import styles from './Link.css'

export function Link({
  href,
  children,
  target = undefined,
  layoutClassName = undefined,
  dataX,
}) {
  return (
    <LinkBase
      className={cx(styles.component, layoutClassName)}
      {...{ href, children, target, dataX }}
    />
  )
}

export function LinkButtonPrimary({
  href,
  children,
  target = undefined,
  layoutClassName = undefined,
  relativeToParent = undefined,
  dataX,
}) {
  const theme = useTheme()
  return (
    <LinkButtonBase
      className={cx(styles.componentButtonPrimary, theme.primary, theme.onPrimary, relativeToParent && styles.relativeToParent, layoutClassName)}
      {...{ href, children, target, dataX }}
    />
  )
}

export function LinkButtonCircularPrimary({
  href,
  children,
  target = undefined,
  layoutClassName = undefined,
  relativeToParent = undefined,
  dataX,
}) {
  const theme = useTheme()
  return (
    <LinkButtonBase
      className={cx(styles.componentButtonCircularPrimary, theme.primary, theme.onPrimary, relativeToParent && styles.relativeToParent, layoutClassName)}
      {...{ href, children, target, dataX }}
    />
  )
}

export function LinkButtonGhost({
  href,
  children,
  target = undefined,
  layoutClassName = undefined,
  relativeToParent = undefined,
  dataX,
}) {
  const theme = useTheme()
  return (
    <LinkButtonBase
      className={cx(styles.componentButtonGhost, theme.border, relativeToParent && styles.relativeToParent, layoutClassName)}
      {...{ href, children, target, dataX }}
    />
  )
}

function LinkBase({ href, children, target, className, dataX }) {
  const rel = target === '_blank' ? 'noopener noreferrer' : null

  return (
    <a
      className={cx(styles.componentBase, className)}
      {...{ href, target, rel }}
      data-x={dataX}
    >
      {children}
    </a>
  )
}

function LinkButtonBase({ href, children, target, className, dataX }) {
  return (
    <LinkBase
      className={cx(styles.componentButtonBase, className)}
      {...{ href, children, target, dataX }}
    />
  )
}
